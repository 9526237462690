import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from '../../Link'
import HeadBodyCTA from '../../UI/HeadBodyCTA'
import Wrapper from '../../UI/Wrapper'
import { ContentBox, IconGrid } from './styles'

interface ThreeIconsProps {
  content: any
}

interface IconSetProps {
  item: {
    headline: string
    icon: {
      description: string
      imgixImage: any
    }
    link: any[]
  }
}

const IconSet = (props: IconSetProps) => {
  const { item } = props
  const image = getImage(item?.icon?.imgixImage)
  if (item.link?.[0]) {
    return (
      <div className="iconSetContainer">
        <Link link={item.link?.[0]}>
          <>
            {image ? (
              <GatsbyImage alt={item?.icon?.description || ''} image={image} />
            ) : null}
            <Typography color="primary" component="p" variant="caption">
              {item.headline}
            </Typography>
          </>
        </Link>
      </div>
    )
  }
  return (
    <div className="iconSetContainer">
      {image ? (
        <GatsbyImage alt={item?.icon?.description || ''} image={image} />
      ) : null}
      <Typography color="primary" component="p" variant="caption">
        {item.headline}
      </Typography>
    </div>
  )
}

const ThreeIcons = (props: ThreeIconsProps) => {
  const { content } = props
  const cta = content?.cta?.[0]

  return (
    <section>
      <Wrapper>
        <Grid container justifyContent="space-between">
          <ContentBox>
            <HeadBodyCTA
              body={content?.body}
              cta={cta}
              headline={content?.headline}
              headlineColor="secondary"
            />
          </ContentBox>
          <IconGrid container item>
            {content?.icon_graphics?.map((item: any) => (
              <IconSet item={item} key={item.id} />
            ))}
          </IconGrid>
        </Grid>
      </Wrapper>
    </section>
  )
}

export default ThreeIcons

import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import HeadBodyCTA from '../../UI/HeadBodyCTA'
import Wrapper from '../../UI/Wrapper'
import {
  SolutionColumn,
  SolutionRowBackground,
  SolutionRowGrid,
  SolutionsSection,
} from './styles'

interface StackedDescriptionsProps {
  content: {
    id: string
    headline: string
    cta_text: string
    internal: {
      type: string
    }
    solution_stacks: {
      feature_image: any
      solutions: {
        id: string
        title: string
        url: string
        product_category: any[]
      }[]
    }[]
  }
}

const StackedDescriptions = (props: StackedDescriptionsProps) => {
  const { content } = props

  return (
    <SolutionsSection>
      <Wrapper>
        <Typography className="subhead" component="h2" variant="h1">
          {content?.headline}
        </Typography>
      </Wrapper>
      {content?.solution_stacks?.map((solutionSet: any, i: number) => {
        const image = getImage(solutionSet?.feature_image?.imgixImage)
        return (
          <SolutionRowBackground key={`solutionSet${i}`} row={i}>
            <Wrapper>
              <SolutionRowGrid row={i}>
                {image && i === 0 ? (
                  <GatsbyImage
                    alt={solutionSet?.feature_image?.description || ''}
                    image={image}
                    loading="lazy"
                    objectFit="contain"
                  />
                ) : null}
                <SolutionColumn>
                  {solutionSet?.solutions?.map((solution: any) => (
                    <HeadBodyCTA
                      body={solution.product_category?.[0]?.short_description}
                      cta={{
                        text: content.cta_text,
                        link: [{ url: solution.url }],
                      }}
                      headline={solution.product_category?.[0]?.title}
                      headlineColor="secondaryDark"
                      key={solution.id}
                      resizeHeadline={true}
                    />
                  ))}
                </SolutionColumn>
                {image && i === 1 ? (
                  <GatsbyImage
                    alt={solutionSet?.feature_image?.description || ''}
                    image={image}
                    loading="lazy"
                    objectFit="contain"
                  />
                ) : null}
              </SolutionRowGrid>
            </Wrapper>
          </SolutionRowBackground>
        )
      })}
    </SolutionsSection>
  )
}

export default StackedDescriptions

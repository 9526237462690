import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const ResourceCardSection = styled.section`
  padding: 88px 0 60px;

  .cardGrid {
    margin-bottom: 40px;
  }

  .subhead {
    color: ${rspTheme.palette.primary.main};
  }

  .desktopCtaContainer {
    display: block;
  }

  .mobileCtaContainer {
    display: none;
    margin-top: 24px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .cardGrid {
      margin-bottom: 24px;
    }

    .subhead {
      font-size: ${rspTheme.typography.h3.fontSize};
      letter-spacing: ${rspTheme.typography.h3.letterSpacing};
      line-height: ${rspTheme.typography.h3.lineHeight};
    }

    .desktopCtaContainer {
      display: none;
    }

    .mobileCtaContainer {
      display: block;
    }
  }
`

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'
import LinkCTA from '../LinkCTA'

interface HeadBodyCTAProps {
  body: string
  headline: string
  headlineColor: 'primary' | 'secondary' | 'primaryDark' | 'secondaryDark'
  resizeHeadline?: boolean
  lineClamp?: boolean
  cta?: {
    text: string
    link: any[]
  }
}

const ClampContainer = styled.div`
  overflow: hidden;
  margin-block-end: 32px;

  .clampBody {
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @media (max-width: 880px) {
      line-clamp: 3;
      -webkit-line-clamp: 3;
    }
  }

  @media (max-width: 768px) {
    margin-block-end: 24px;
  }
`

const HeadBodyCTA = (props: HeadBodyCTAProps) => {
  const {
    body,
    cta,
    headline,
    headlineColor,
    resizeHeadline = false,
    lineClamp,
  } = props
  const colorOptions = {
    primary: rspTheme.palette.primary.main,
    primaryDark: rspTheme.palette.primary.dark,
    secondary: rspTheme.palette.secondary.main,
    secondaryDark: rspTheme.palette.secondary.dark,
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      headline: {
        marginBottom: '24px',
        maxWidth: '20ch',
        color: colorOptions[headlineColor],
        [theme.breakpoints.down('md')]: resizeHeadline
          ? {
              fontSize: theme.typography.h5.fontSize,
              lineHeight: theme.typography.h5.lineHeight,
              marginBottom: '18px',
              maxWidth: 'unset',
            }
          : {},
      },
      body: {
        marginBlockEnd: '32px',
      },
    }),
  )

  const classes = useStyles(rspTheme)

  return (
    <>
      <Typography className={classes.headline} component="h2" variant="h2">
        {headline}
      </Typography>
      {lineClamp ? (
        <ClampContainer>
          <Typography className="clampBody" component="p" variant="body1">
            {body}
          </Typography>
        </ClampContainer>
      ) : (
        <Typography className={classes.body} component="p" variant="body1">
          {body}
        </Typography>
      )}
      {cta ? (
        <LinkCTA
          arrow={true}
          directUrl={cta.link?.[0]?.url}
          link={cta.link?.[0]}
          size="buttonMd"
          variant="primary"
        >
          {cta.text}
          <span className="sr-only">: {headline}</span>
        </LinkCTA>
      ) : null}
    </>
  )
}

export default HeadBodyCTA

import 'pure-react-carousel/dist/react-carousel.es.css'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { CarouselProvider, Dot, Slide } from 'pure-react-carousel'
import React from 'react'

import {
  Back,
  ButtonContainer,
  DotContainer,
  Next,
  StyledSlider,
} from './styles'

interface CarouselProps {
  slides: any[]
  maxSlidesToShow?: number
  className?: 'white' | 'plum'
  isSolutionsCarousel?: boolean
}

const Carousel = (props: CarouselProps) => {
  const {
    slides,
    maxSlidesToShow = 3,
    className = 'white',
    isSolutionsCarousel,
  } = props
  const isTablet = useMediaQuery('(max-width:768px)')
  const isMobile = useMediaQuery('(max-width:640px)')
  const slidesToShow = isMobile ? 1 : isTablet ? 2 : maxSlidesToShow

  return (
    <CarouselProvider
      naturalSlideHeight={className === 'white' ? 420 : isTablet ? 460 : 320}
      naturalSlideWidth={!isSolutionsCarousel ? 316 : isMobile ? 340 : 400}
      step={slidesToShow}
      totalSlides={slides.length}
      visibleSlides={slidesToShow}
    >
      <StyledSlider className={className} role={undefined}>
        {slides.map((slide: any, i: number) => (
          <Slide
            aria-selected={undefined}
            index={i}
            key={`slide${i}`}
            role={undefined}
          >
            {slide}
          </Slide>
        ))}
      </StyledSlider>
      <ButtonContainer>
        <Back>
          <>
            <NavigateBeforeIcon />
            <span className="sr-only">Carousel backward</span>
          </>
        </Back>
        <DotContainer>
          {slides.map((slide: any, i: number) => {
            if (i === 0 || (i % slidesToShow === 0 && i != 1) || isMobile) {
              return <Dot key={`dot${i}`} slide={i} />
            }
            return null
          })}
        </DotContainer>
        <Next>
          <>
            <NavigateNextIcon />
            <span className="sr-only">Carousel forward</span>
          </>
        </Next>
      </ButtonContainer>
    </CarouselProvider>
  )
}

export default Carousel

import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import ClientLogos from '../components/ClientLogos'
import Hero from '../components/Hero'
import CTAHeadline from '../components/Homepage/CTAHeadline'
import ResourceCards from '../components/Homepage/ResourceCards'
import StackedDescriptions from '../components/Homepage/StackedDescriptions'
import SubHeroText from '../components/Homepage/SubHeroText'
import ThreeIcons from '../components/Homepage/ThreeIcons'
import TrainingPrograms from '../components/Homepage/TrainingPrograms'
import Layout from '../components/Layout'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupOrganization } from '../components/SchemaMarkup/SchemaMarkupOrganization'
import TrainStopSection from '../components/TrainstopSection'

const HomePage = (props: PageProps<Queries.HomePageQuery>) => {
  const { data } = props
  const {
    contentstackPageLanding,
    allContentstackPageSalesTrainingProgram,
    allContentstackPageResource,
  } = data ?? {}
  const pageData = contentstackPageLanding
  const trainingPrograms = allContentstackPageSalesTrainingProgram?.nodes
  const resources = allContentstackPageResource?.nodes

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const renderSection = (section: any) => {
    switch (section?.internal?.type) {
      case 'Contentstack_section_sales_training_program_links':
        return (
          <TrainingPrograms
            content={section}
            key={section?.id}
            programs={trainingPrograms}
          />
        )
      case 'Contentstack_section_resource_card_carousel':
        return (
          <ResourceCards
            content={section}
            key={section?.id}
            resources={resources}
          />
        )
      case 'Contentstack_section_client_logos':
        return <ClientLogos content={section} key={section?.id} />
      case 'Contentstack_section_cta_headline':
        return <CTAHeadline content={section} key={section?.id} />
      case 'Contentstack_section_stacked_solutions':
        return <StackedDescriptions content={section} key={section?.id} />
      case 'Contentstack_section_train_stop':
        return (
          <TrainStopSection
            ctaText={section.cta_text}
            headline={section.headline}
            isHeroTrainStop={section.is_hero_train_stop}
            key={section?.id}
            slides={section.slides}
          />
        )
      default:
        return null
    }
  }

  return (
    <Layout
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      showBreadcrumb={false}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Helmet>
        <meta
          content="HrFXtObs3zdlN5D6UrkMNJA-XhaxuVYiw5SKfv3-qmc"
          name="google-site-verification"
        />
      </Helmet>
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.sub_hero_text_richtext ? (
        <SubHeroText content={pageData?.sub_hero_text_richtext} />
      ) : null}
      {pageData?.introduction?.[0] ? (
        <ThreeIcons content={pageData?.introduction[0]} />
      ) : null}
      {pageData?.sections?.map(renderSection)}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.HomePageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageLanding?.schema_markup} />
    <SchemaMarkupOrganization offices={data.allContentstackTopicOffice.nodes} />
  </>
)

export default HomePage

export const query = graphql`
  query HomePage($pageId: String, $locale: String) {
    contentstackPageLanding(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      url
      publish_details {
        locale
      }
      locale
      chinese_version_url
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      sub_hero_text_richtext
      introduction {
        ...ThreeIconOverviewSection
      }
      sections {
        ... on Contentstack_section_client_logos {
          ...ClientLogos
        }
        ... on Contentstack_section_sales_training_program_links {
          ...SalesTrainingProgramLinksSection
        }
        ... on Contentstack_section_resource_card_carousel {
          ...ResourceCardCarouselSection
        }
        ... on Contentstack_section_cta_headline {
          ...CtaHeadlineSection
        }
        ... on Contentstack_section_stacked_solutions {
          ...StackedSolutionsSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
      }
    }
    allContentstackPageSalesTrainingProgram(
      filter: { publish_details: { locale: { eq: $locale } } }
      sort: { fields: publish_details___time, order: DESC }
      limit: 100
    ) {
      nodes {
        id
        url
        program {
          id
          title
          sales_stage {
            id
            title
          }
        }
      }
    }
    allContentstackPageResource(
      filter: { publish_details: { locale: { eq: $locale } } }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        id
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }
    allContentstackTopicOffice {
      nodes {
        uid
        phone
        title
        locale
        email
        address
      }
    }
  }
`

import styled from 'styled-components'

import linkTriangle from '../../../images/linkTriangle.svg'
import { rspTheme } from '../../../styles/rspTheme'

export const ProgramsSection = styled.section`
  color: ${rspTheme.palette.secondary.main};
  padding: 96px 0 72px;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}) {
    padding: 48px 0;
  }
`

export const ImageContainer = styled.div`
  margin: 40px 0 48px;
  border-radius: 8px;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}) {
    margin: 32px 0;
  }
`

export const ProgramGrid = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-column-gap: 5%;
  grid-row-gap: 8px;
  margin: 0;
  padding: 0;

  .categorySubhead {
    margin-bottom: 24px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      font-size: 1.8rem;
      color: ${rspTheme.palette.secondary.dark};
    }
  }

  .subList {
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-bottom: 32px;
    }
  }

  .subListItem {
    max-width: 280px;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    margin-left: -15px;
    margin-bottom: 12px;

    &:hover,
    &:focus-within {
      color: ${rspTheme.palette.primary.main};
      background-color: ${rspTheme.palette.secondary.background};
    }

    &:focus-within a {
      text-decoration-color: inherit;
    }

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      max-width: unset;
    }
  }

  .subListItem a {
    color: inherit;
    display: block;
    padding: 5px 24px 5px 15px;
    text-decoration-color: transparent;
    transition: all 0.2s ease-in-out;
    position: relative;
    background-color: transparent;

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 5px;
      height: 9px;
      bottom: 12px;
      transform: translateX(6px);
      background-image: url(${linkTriangle});
    }
  }
`

import { ButtonBack, ButtonNext, Slider } from 'pure-react-carousel'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

const buttonStyle = `
width: 40px;
height: 40px;
background-color: transparent;
border: 1px solid ${rspTheme.palette.primary.main};
border-radius: 50%;
color: ${rspTheme.palette.primary.main};
padding: 0;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
transition: all 0.3s ease-in-out;

&:hover:not(:disabled),
&:focus:not(:disabled) {
  color: ${rspTheme.palette.primary.background};
  background-color: ${rspTheme.palette.primary.main};
}

&:disabled {
  opacity: 0.4;
  cursor: unset;
}
`

export const Back = styled(ButtonBack)`
  ${buttonStyle}
`
export const Next = styled(ButtonNext)`
  ${buttonStyle}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto 0;

  > * {
    flex-shrink: 0;
  }

  .carousel__dot {
    border: none;
    width: 8px;
    height: 8px;
    background-color: ${rspTheme.palette.primary.main};
    border-radius: 50%;
    margin: 0 20px;
    padding: 0;
    transform: scale(1);
    transform-origin: center;
    transition: all 0.2s ease-in-out;

    &.carousel__dot--selected {
      display: inline-block;
      transform: scale(2);
      background-color: ${rspTheme.palette.secondary.main};
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    margin: 0 0 36px;

    > button {
      margin: 0 24px;
    }
  }
`

export const DotContainer = styled.div`
  margin: 0 24px;
  max-width: 360px;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 4px;

  > * {
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledSlider = styled(Slider)`
  &.white {
    padding: 16px;
    margin-left: -32px;
    margin-right: -32px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 16px;
      background-color: white;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 16px;
      background-color: white;
      z-index: 20;
    }

    .carousel__inner-slide {
      padding: 16px;
    }
  }

  .carousel__inner-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      height: 100%;
      width: 100%;

      @media (max-width: 640px) {
        width: auto;
      }
    }
  }

  @media (max-width: 640px) {
    &.white {
      margin-left: -5%;
      margin-right: -5%;
    }
  }
`

import Box from '@material-ui/core/Box'
import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'section';
  max-height: 480px;

  & .gatsby-image-wrapper {
    grid-area: section;
    width: 100%;
    height: 100%;

    picture > img {
      height: auto !important;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      object-position: top center;
      top: 0;
    }
  }
`

export const ContentContainer = styled(Box)`
  grid-area: section;
  align-self: center;
  z-index: 10;

  @media (max-width: 640px) {
    padding: 88px 0 96px;
  }
`

export const Headline = styled.h2`
  color: white;
  font-size: ${rspTheme.typography.h1.fontSize};
  font-weight: ${rspTheme.typography.h1.fontWeight};
  letter-spacing: ${rspTheme.typography.h1.letterSpacing};
  line-height: ${rspTheme.typography.h1.lineHeight};
  margin-bottom: 48px;
  max-width: 650px;

  @media (max-width: 768px) {
    font-size: 3.6rem;
    letter-spacing: -0.2px;
    line-height: 1.38;
    margin-bottom: 40px;
    max-width: 28ch;
  }
`

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import Carousel from '../../Carousel'
import FeatureCardVertical from '../../UI/FeatureCardVertical'
import LinkCTA from '../../UI/LinkCTA'
import Wrapper from '../../UI/Wrapper'
import { ResourceCardSection } from './styles'

interface CardCarouselProps {
  content: {
    id: string
    headline: string
    cta_button: any[]
    filters?: any[]
    promoted_resources?: any[]
    max_resources?: number
  }
  resources: {
    url: string
    resource: {
      headline: string
      short_description: string
      is_featured: 'Yes' | 'No'
      feature_image: any
      resource_type: {
        id: string
        title: string
      }[]
      resource_topic: {
        id: string
        title: string
      }[]
      industry: {
        id: string
        title: string
      }[]
      roles: {
        id: string
        title: string
      }[]
      products: {
        id: string
        title: string
      }[]
    }[]
  }[]
}

const CardCarousel = (props: CardCarouselProps) => {
  const { content, resources } = props
  const filter = content?.filters?.[0]
  const maxNumberOfResources = content?.max_resources || 15

  const resourcesByFilter = filter
    ? resources.filter((resource) => {
        const resourceData = resource?.resource?.[0]

        switch (filter.internal.type) {
          case 'Contentstack_topic_resource_type':
            return filter.id === resourceData?.resource_type?.[0]?.id
          case 'Contentstack_topic_industry':
            return filter.id === resourceData?.industry?.[0]?.id
          case 'Contentstack_topic_role':
            return filter.id === resourceData?.roles?.[0]?.id
          case 'Contentstack_topic_product':
            return filter.id === resourceData?.products?.[0]?.id
          default:
            return null
        }
      })
    : []

  resourcesByFilter.sort((a, b) => {
    if (
      a.resource?.[0]?.is_featured === 'Yes' &&
      b.resource?.[0]?.is_featured === 'No'
    ) {
      return 1
    } else if (
      a.resource?.[0]?.is_featured === 'No' &&
      b.resource?.[0]?.is_featured === 'Yes'
    ) {
      return -1
    }
    return 0
  })

  const promotedResources = content?.promoted_resources ?? []
  const slidesToDisplay = [...promotedResources, ...resourcesByFilter]
  const slidesToMap =
    slidesToDisplay.length > maxNumberOfResources
      ? slidesToDisplay.slice(0, maxNumberOfResources)
      : slidesToDisplay

  const slides = slidesToMap?.map((resource: any, i: number) => (
    <FeatureCardVertical
      body={resource.resource?.[0]?.short_description || ''}
      cardType={resource.resource?.[0]?.resource_type?.[0]?.title || ''}
      featureImage={resource.resource?.[0]?.feature_image}
      headline={resource.resource?.[0]?.headline || ''}
      key={`card${i}`}
      linkTarget={resource.url}
    />
  ))

  return (
    <ResourceCardSection>
      <Wrapper>
        <Grid className="cardGrid" container justifyContent="space-between">
          <Typography className="subhead" component="h2" variant="h2">
            {content?.headline}
          </Typography>
          <div className="desktopCtaContainer">
            <LinkCTA
              arrow={true}
              link={content?.cta_button?.[0]?.link?.[0]}
              variant={
                content?.cta_button?.[0]?.variant.toLowerCase() || 'outline'
              }
            >
              {content?.cta_button?.[0]?.text ||
                content?.cta_button?.[0]?.link?.[0]?.text}
            </LinkCTA>
          </div>
        </Grid>
        {slides?.length > 0 ? <Carousel slides={slides} /> : null}
        <Grid
          alignItems="center"
          className="mobileCtaContainer"
          container
          justifyContent="center"
        >
          <LinkCTA
            arrow={true}
            link={content?.cta_button?.[0]?.link?.[0]}
            variant={
              content?.cta_button?.[0]?.variant.toLowerCase() || 'outline'
            }
          >
            {content?.cta_button?.[0]?.text ||
              content?.cta_button?.[0]?.link?.[0]?.text}
          </LinkCTA>
        </Grid>
      </Wrapper>
    </ResourceCardSection>
  )
}

export default CardCarousel

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import LinkCTA from '../../UI/LinkCTA'
import Wrapper from '../../UI/Wrapper'
import { ContentContainer, Headline, Section } from './styles'

interface CTAHeadlineProps {
  content: any
}

const CTAHeadline = (props: CTAHeadlineProps) => {
  const { content } = props
  const image = getImage(content?.background_image?.imgixImage)

  return (
    <Section>
      {image ? (
        <GatsbyImage
          alt={content?.background_image?.description || ''}
          image={image}
        />
      ) : null}
      <ContentContainer>
        <Wrapper>
          <Headline>{content?.headline}</Headline>
          <LinkCTA
            arrow={true}
            link={content?.cta?.[0]?.link?.[0]}
            size="buttonLg"
            variant="primary"
          >
            {content?.cta?.[0]?.text || content?.cta?.[0]?.link?.[0]?.text}
          </LinkCTA>
        </Wrapper>
      </ContentContainer>
    </Section>
  )
}

export default CTAHeadline

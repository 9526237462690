import React from 'react'

import Wrapper from '../../UI/Wrapper'
import { ContentContainer } from './styles'

interface SubHeroTextProps {
  content: string
}
const SubHeroText = ({ content }: SubHeroTextProps) => (
  <ContentContainer>
    <Wrapper dangerouslySetInnerHTML={{ __html: content }} />
  </ContentContainer>
)

export default SubHeroText

import React from 'react'

type PropTypes = {
  offices: Queries.HomePageQuery['allContentstackTopicOffice']['nodes']
}

const SAME_AS = [
  'https://www.linkedin.com/company/richardsonsalesperformance',
  'https://twitter.com/RichardsonSales',
  'https://www.facebook.com/richardsonsalesperformance',
  'https://www.facebook.com/richardsonsalesperformance',
  'https://www.youtube.com/channel/UC7oSnaFuBwhn8IM7pcvlxPw',
  'https://open.spotify.com/user/uxfhz54pj0mt2k02qhpz6iwxo',
  'https://www.instagram.com/richardsonsalestraining/',
]

const toSchema = (offices: PropTypes['offices']) => {
  // TODO: the locales coming from GraphQL has duplicates that need to be removed, once the duplicates removed we can removed the filtered array
  const filteredOffices = Object.values(
    Object.fromEntries<PropTypes['offices']['0']>(
      offices.map((office) => [office.title, office]),
    ),
  )

  const mainOffice = filteredOffices.find(
    ({ title }) => title === 'United States Office',
  )
  const otherOffices = filteredOffices.filter(
    ({ title }) => title !== 'United States Office',
  )

  return {
    '@context': 'https://www.schema.org/',
    '@type': 'SalesPerformanceTraining',
    name: `Richardson - ${mainOffice?.title}`,
    url: 'https://www.richardson.com/',
    logo: 'https://richardson.imgix.net/https%3A%2F%2Fimages.contentstack.io%2Fv3%2Fassets%2Fblt55a486d156678036%2Fbltdb33fa68f9d639c7%2F60dc553594d4824720fe1c38%2Flogo-light-theme.png?ixlib=gatsbySourceUrl-2.0.2&auto=format%2Ccompress&q=90&w=181&h=29&s=219e72eb6bd147a7493e76361be3bec5',
    description: 'We are sales performance training company',
    telephone: mainOffice?.phone,
    email: mainOffice?.email,
    sameAs: [...SAME_AS],
    address: mainOffice?.address,
    department: otherOffices.map((office) => ({
      '@type': 'SalesPerformanceTraining',
      name: `Richardson - ${office.title}`,
      description: 'We are sales performance training company',
      telephone: office?.phone,
      address: office?.address,
      sameAs: [...SAME_AS],
    })),
  }
}

export const SchemaMarkupOrganization = ({ offices }: PropTypes) => (
  <script type="application/ld+json">
    {JSON.stringify(toSchema(offices))}
  </script>
)

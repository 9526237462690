import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

export const ContentBox = styled(Box)({
  padding: '12rem 0',
  width: 'calc(50% - 24px)',

  '@media (max-width: 1200px)': {
    width: 'calc(80% - 10px)',
    padding: '48px 0 36px',
    display: 'flex',
    flexDirection: 'column',
  },
})

export const IconGrid = styled(Grid)({
  width: '50%',
  justifyContent: 'space-between',
  alignSelf: 'center',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',

  '@media (max-width: 1200px) and (min-width: 800px), (max-width: 500px)': {
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    minWidth: 'unset',
    '& > div': {
      margin: '0 16px 36px',
    },
  },
  '@media (max-width: 800px) and (min-width: 500px)': {
    width: '100%',
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'end',
    '& > div': {
      margin: '0 16px 36px',
    },
  },
  '& .iconSetContainer': {
    '& a': {
      color: 'inherit',
      textDecorationColor: 'transparent',
      transition: 'text-decoration-color 0.2s ease-in-out',
      '&:hover': {
        textDecorationColor: 'inherit',
      },
      '&:focus': {
        textDecorationColor: 'inherit',
      },
    },
    '& p': {
      textAlign: 'center',
      marginTop: '21px',
    },
  },
})

import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const SolutionsSection = styled.section`
  background-color: ${rspTheme.palette.secondary.background};
  padding: 96px 0;
  text-align: center;
  box-shadow: 0px -15px 15px 0px rgba(0, 0, 0, 0.14);

  .subhead {
    max-width: 22ch;
    margin: 0 auto;
    color: ${rspTheme.palette.primary.main};
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 40px 0 48px;
    text-align: left;
    box-shadow: none;

    .subhead {
      margin: unset;
      font-size: ${rspTheme.typography.h2.fontSize};
      letter-spacing: ${rspTheme.typography.h2.letterSpacing};
      line-height: ${rspTheme.typography.h2.lineHeight};
    }
  }
`

interface SolutionRowProps {
  row: number
}

export const SolutionRowBackground = styled.div<SolutionRowProps>`
  position: relative;
  overflow-x: visible;

  .gatsby-image-wrapper {
    ${({ row }) => (row === 0 ? 'margin-left: -5%;' : 'margin-right: -5%;')}
  }
`

export const SolutionRowGrid = styled.div<SolutionRowProps>`
  display: grid;
  grid-template-columns: ${({ row }) => (row === 0 ? '3fr 2fr' : '2fr 3fr')};
  grid-column-gap: 24px;
  margin: 80px 0;
  text-align: left;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-template-columns: 1fr;
    margin: 24px 0 0;
  }
`

export const SolutionColumn = styled.div`
  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-row-start: 2;
    margin-top: 24px;
  }

  > * + h2 {
    margin-top: 88px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-top: 48px;
    }
  }
`

import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const ContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 60px 0;
  border-bottom: solid 1px #fff;
  color: #21154a;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 0.15px;
  font-size: 2.1rem;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 30px 0;
  }
`

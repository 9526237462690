import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from '../../Link'
import Wrapper from '../../UI/Wrapper'
import { ImageContainer, ProgramGrid, ProgramsSection } from './styles'

interface TrainingProgramsProps {
  content: any
  programs: any[]
}

const TrainingPrograms = (props: TrainingProgramsProps) => {
  const { content, programs } = props
  const image = getImage(content?.feature_image?.imgixImage)
  const salesStages = content?.sales_stages

  return (
    <ProgramsSection>
      <Wrapper>
        <Typography color="inherit" component="h2" variant="h2">
          {content?.headline}
        </Typography>
        {image ? (
          <ImageContainer>
            <GatsbyImage
              alt={content?.feature_image?.description || ''}
              image={image}
            />
          </ImageContainer>
        ) : null}
        <ProgramGrid>
          {salesStages?.map((stage: any) => (
            <li key={stage.id}>
              <Typography
                className="categorySubhead"
                component="h3"
                variant="h3"
              >
                {stage.title}
              </Typography>
              <ul className="subList">
                {stage?.programs?.map((program: any) => {
                  const linkTarget = programs.filter(
                    (programPage: any) =>
                      programPage.program?.[0]?.id === program.id,
                  )
                  return (
                    <Typography
                      className="subListItem"
                      color="textPrimary"
                      component="li"
                      key={program.id}
                      variant="subtitle1"
                    >
                      <Link directUrl={linkTarget?.[0]?.url || '/'}>
                        {program?.headline}
                      </Link>
                    </Typography>
                  )
                })}
              </ul>
            </li>
          ))}
        </ProgramGrid>
      </Wrapper>
    </ProgramsSection>
  )
}

export default TrainingPrograms
